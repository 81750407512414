import { useState,useEffect } from 'react';
import { useParams } from "react-router-dom";
import React from "react";
//import DoneIcon from '@mui/icons-material/DoneOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
//import {blue} from "@mui/material/colors/";
import Paper from '@mui/material/Paper';
import { CircularProgress } from '@material-ui/core';


function App() {
  const {installmentid} = useParams();
  //const {signature} = useParams();

  const [res,setResponse] = useState(null);
  //const [isLoading,setIsLoading] = useState(false);
  const CurrentDate = () => {
    const now = new Date();
    return now.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };
  const currentDate = CurrentDate();

  //  const color=green[500];

useEffect(() => {
//setIsLoading(true);
const API_URL = process.env.REACT_APP_BACKEND_URL+"/services/pendinginstallmentsuccess";
//  console.log(installmentid); // ▶ URLSearchParams {}
  //let data;
  let url = window.location.href;

  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'plain/text');


  let urlObj = new URL(url);

// Use the URLSearchParams interface to get the signature parameter
  let signature = encodeURIComponent(urlObj.searchParams.get('signature'));

//console.log(signature); // Outputs: $2a$10$uoa7lvrcYIHiXUgE00xBhOI6i3hjvIp6zQaRM6zzELi3OSnPgWYVa

let params={installmentid:installmentid,signature:signature};
let queryString = new URLSearchParams(params).toString();
//console.log(queryString);


  fetch(API_URL+"?"+queryString,{method:'POST'})
    .then(response => response.json())
    .then(data =>
      { setResponse(data);
        //console.log(data);
      })
    .catch(err=>{
      console.log("ERROR reg success",err);
    })

},[]);

  return (
    <div >
      {res&& <div className="SuccessPage"
      style={{ width: "100%", display: "flex", justifyContent: "flex-end" ,  padding: "15px"}}>
        <CheckCircleIcon style={{ color:"green", fontSize:"40px" }}/>
          <Paper>Your payment is not complete, until it has been approved by the OMC staff.<br/>An email was sent to <b>{res.email}</b>.
          <br/>Pease check you spam/junk folder also.<br />Your payment is still pending:<br/>
          <p><b>Payment Id: </b> {res.installmentId}</p>
          <p><b>Invoice Number: </b> {res.invoiceNumber}</p>
          <p><b>Membership Year: </b> {res.membershipYear}</p>
          <p><b>Organisation Name: </b> {res.organisationName}</p>
          <p><b>First Name: </b> {res.firstName}</p>
            <p><b>Last Name: </b>{res.lastName}</p>
            <p><b>Pending amount: </b>{res.totalFee}$</p>
            <p><b>Date Paid: </b>{currentDate}</p>
            <p><b>Please save this information for future reference.</b></p>
            {res.discount > 0 && (
        <strong style={{
          color: 'red',
          fontWeight: 'bold'
        }}>
          Congratulations, you just saved ${res.discount}
          <br />
          for paying before the deadline!
        </strong>
      )}
            </Paper>

        </div>}
        {!res &&
        <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                      <CircularProgress color="secondary" />
        <span style={{ justifyContent: "center", position: "fixed", top: "55%" }}>Loading...please wait</span>
        </div>
        }
    </div>
  );
}
export default App;
