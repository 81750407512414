import {useState,useEffect,useRef} from "react";
import React from "react";
import axios from "axios";
import { CircularProgress, Button } from '@material-ui/core';
import { forwardRef } from 'react';

//import ButtonGroup from '@material-ui/core/ButtonGroup';
//import {Link,useHistory } from 'react-router-dom';

//import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
/**/
import CreditCardIcon from '@mui/icons-material/CreditCard';
import Alert from '@mui/material/Alert';
//import Input from '@mui/material/Input';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
//import Select from '@material-ui/core/Select';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/';
import {  LocalizationProvider } from '@mui/x-date-pickers';

import moment from 'moment';
import { createTheme, ThemeProvider } from '@mui/material/styles';
//import Moment from 'react-moment';
//import 'moment-timezone';
import Typography from '@mui/material/Typography';

import {useNavigate} from 'react-router-dom';

//import MuiPhoneNumber from 'mui-phone-number';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Document, Page } from 'react-pdf';

import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import {  IconButton } from '@material-ui/core';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';



const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // Blue
    },
    secondary: {
      main: '#ff0033', // Red
      contrastText: '#ffffff', // Add this line

    },
  },
});

// Define your custom color
const customColor = {
  main: '#205396', // Main color
  light: '#0073ba', // Lighter shade
  dark: '#023c70', // Darker shade
  contrastText: '#ee2424', // Text color for contrast
  divider: '#ee2424', // Divider color
};

const buttonStyle = {
    width: 200,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };



const CreateRegistration=() => {
  //const {section} = useParams();
  //const section = "Juror/Trainer";
  //console.log(section);
  //let formdate=  moment('2024-02-29', 'YYYY-MM-DD').format('YYYY-MM-DD');

  //const currentDate = new Date();
  const currentDate = moment().startOf('day').format('YYYY-MM-DD');


  const [showBeforeUnloadMessage, setShowBeforeUnloadMessage] = useState(true);
  const showBeforeUnloadMessageRef = useRef(showBeforeUnloadMessage);

  const [openConfirm, setOpenConfirm] = useState(false);
  const [isSubmit,setIsSubmit] = useState(false);

  const todayDate= moment().format('YYYY-MM-DD');
  const [form, setform] = useState();

  const initialValues = {president:"",country:"",organisationCode:"",organisationName:"",
      invoiceNumber:"",fname:"",lname:"",dueDate:"",email:"",phone: "", comment:""
     ,pendingPayment:false,fullyPaid:"",totalAmount:"",amountToPay:"",paymentType:"",submitionDate:todayDate,
     fileName:"",pendFile:"",pendingAmount:0,discount:0
    };

  //FormValues
  const [formValues,setFormValues] = useState(initialValues);
  const [formErrors,setFormErrors] = useState([]);

//Pay
  const [isPay,setIsPay] = useState(false);

  const [isLoading,setIsLoading] = useState(false);
  const [validated,setValidated] = useState(false);

  const [loadingmessage,setLoadingmessage]=useState("Loading...Please wait");
  const [isChecked, setIsChecked] = useState(false);

  const [codeAccepted,setCodeAccepted] = useState(false);
  const [codeValid,setCodeValid] = useState(false);
  const [zeroRemaining,setZeroRemaining] = useState(false);
  //Accepted file types for online submition
//  const types = ['image/png', 'image/jpeg','image/jpg','image/gif','image/tiff'];
  const types = ['image/png', 'image/jpeg','image/jpg','image/gif','image/tiff', 'application/pdf'];

  const maxfilesize="5000000";

  const navigate=useNavigate();

/*Dialog for Code*/
  const [open, setOpen] = React.useState(false);

  //PDF Pagination and Dialog
  const [viewPendingFile,setViewPendingFile]=useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);


  //const [selectedValue, setSelectedValue] = React.useState(codeAccepted);
  const handleCloseConfirm = () => {
    setOpenConfirm(false); // Close the dialog without confirming
    setShowBeforeUnloadMessage(true);

  };

  const pendingPaymentChange=(e)=> {
    const { name, value } = e.target;
   //  if (typeof formValues.pendingPayment === 'string') {
   //  console.log('formValues.pendingPayment is a string');
   // } else {
   //  console.log('formValues.pendingPayment is not a string');
   // }
 setFormValues({ ...formValues,[name]: value === 'true' });

};



// const pendingPaymentChange=(e)=> {
//  const { name, value } = e.target;
//
//
//  setFormValues({ ...formValues,[name]: value });
// };


const checkHandler = () => {
  setIsChecked(!isChecked)

};


   const inputStyle = {
    borderColor: formErrors.some(error => error.name === "phone") ? 'red' : '',
   };

   const secondBoxRef = useRef();

/*   const handleFirstBoxBlur = () => {
     secondBoxRef.current.focus();

     window.scrollTo({
       top: secondBoxRef.current.offsetTop-100,
       behavior: 'smooth'
     });
   };*/

  const handleClickOpen = async () => {
    const errors=[];
    let data;
    const ORG_API_URL = process.env.REACT_APP_BACKEND_URL+"/services/invoice";

    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'plain/text');

    let formatedDueDate= moment(formValues.dueDate).format('YYYY-MM-DD');
    const params={invoiceNumber:formValues.invoiceNumber,organisationCode:formValues.organisationCode};
    const queryString = new URLSearchParams(params).toString();
    //console.log(queryString);


    if (!formValues.organisationCode)
      {errors.push({name:"organisationCode",value:"Organization Code is required!"});}
    else if (!formValues.invoiceNumber)
      {errors.push({name:"invoiceNumber",value:"Invoice Number is required!"});}
    //else if (!formValues.dueDate)
    //    {errors.push({name:"dueDate",value:"Invoice Due Date is required!"});}
    else{
            try {
            setIsLoading(true);

             // Make a GET request to your backend API endpoint using Axios
             const response = await axios.get(ORG_API_URL+"?"+queryString);
             let data = response.data;
            // console.log(response);

             if (!response.data) {//Not valid combination
                //console.log('Data is empty');
                //setFormValues({ ...formValues,invoiceNumber:"",organisationCode:"",dueDate:""});
                setCodeValid(false);setOpen(true);
              }
              else {//Valid combination
                //console.log('Data is not empty');
              //  console.log(response.data);
                //Update the state with the retrieved data
                setFormValues({ ...formValues,amountPaid:response.data.amountPaid,country:response.data.country,dueDate:response.data.dueDate,
                  fullyPaid:response.data.fullyPaid,paymentType:response.data.paymentType,organisationName:response.data.organisationName,
                        president:response.data.president,totalAmount:response.data.totalAmount,pendingAmount:response.data.pendingAmount,discount:response.data.discount
                        /*amountToPay:response.data.totalAmount - response.data.amountPaid < 0 ? 0 : response.data.totalAmount - response.data.amountPaid*/
                      });

                if (response.data.totalAmount - response.data.amountPaid <=0 || response.data.fullyPaid)
                  {setZeroRemaining(true);setCodeValid(true);  setOpen(true);}
                else {
                  setCodeValid(true);  setOpen(true);setZeroRemaining(false);
                }
              }
           } catch (error) {//Error from server
             // Handle errors
             //setFormValues({ ...formValues,invoiceNumber:"",organisationCode:"",dueDate:""});
             console.error('Error fetching data, please check yout connection and try again...', error);
             setCodeValid(false);setOpen(false);
             //setError('Error fetching data');
           } finally {
             // Set loading to false when the request is complete
             setIsLoading(false);
           }
        }
    setFormErrors(errors);

  };

  const handleCloseYes = (value: string) => {
    setOpen(false);
    setCodeAccepted(true);
  //  secondBoxRef.current.scrollIntoView({ behavior: 'smooth' });
    window.scrollTo({
      top: secondBoxRef.current.offsetTop-100,
      behavior: 'smooth'
});

    //setCodeValid(true);
  };
  const handleCloseNo = (value: string) => {
    setOpen(false);
    setCodeAccepted(false);
    //setCodeValid(false);
  //  setFormValues({ ...formValues,invoiceNumber:"",organisationCode:"",dueDate:null});

  };

  const handleCloseDis = (value: string) => {
    setCodeAccepted(false);
    setOpen(false);
    //setCodeValid(false);
  //  setFormValues({ ...formValues,invoiceNumber:"",organisationCode:"",dueDate:null});
  };



  useEffect(() => {
    //console.log('Trig');
  }, [showBeforeUnloadMessage]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      if (showBeforeUnloadMessage) {
        event.returnValue = "Are you sure you want to leave?";
        return "Are you sure you want to leave?";
      }
    };
    if (showBeforeUnloadMessage) {
  window.addEventListener('beforeunload', handleBeforeUnload);
}

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [showBeforeUnloadMessage]);


//Insert Code input
const codeChange = (e) =>{
  const { name, value } = e.target;
  setFormValues({ ...formValues,[name]: value});
}
//Function to "hear" Enter key button on code insert
const handleKeypress = (e) =>{
//console.log(e.key);
   if (e.key === "Enter" ) {
     document.getElementById("codebutton").click();
   }
 }

//Form Changes
  const handleChange = (e) => {
      const { name, value } = e.target;
      setFormValues({ ...formValues,[name]: value});

      if (e.target.name === 'amountToPay') {
    let newValue = e.target.value;

    // Filter out non-numeric characters
    newValue = newValue.replace(/[^0-9.]/g, '');

    // Convert the new value to a number
  //  const numericNewValue = parseFloat(newValue.replace(/[^0-9.-]+/g, ""));

    // Check if due date has passed
    const dueDatePassed = formValues.dueDate >= currentDate;

    // Apply MaxAllowedAmount only if due date hasn't passed
    const finalValue = dueDatePassed
      ? Math.min(newValue, formValues.totalAmount - formValues.amountPaid - formValues.discount)
      : newValue;

    // Ensure the value is not negative
    const validFinalValue = Math.max(0, finalValue);

    setFormValues({ ...formValues, ['amountToPay']: validFinalValue });
  }
      else  if (e.target.name ==='pendFile')
         {//TODO: SETFORMERRORS
           //console.log(e.target.files[0]);
          // if (e.target.files[0]){
                //console.log(e.target);
                let selected=e.target.files[0];

               //Everything is OK
               if (selected && types.includes(selected.type) && selected.size < maxfilesize) {
                  setFormValues(prevFormValues => ({
                   ...prevFormValues,
                   [name]: selected,
                   ['fileName']: selected.name
                  }));
                 }
                else if (selected && selected.size>maxfilesize) {
                   e.target.value='';
                   setFormValues(prevFormValues => ({
                    ...prevFormValues,
                    [name]: '',
                    ['fileName']: ''
                   }));
                   //setFile('');
                   alert('File too large, please upload file less than '+maxfilesize/1000000+'MB');
                   //return {<Alert severity="error">Accepted file types: {types} Max file size: 5MB </Alert>}
                 }//Wrong file type
                else if (selected&&!types.includes(selected.type))
                {
                  e.target.value='';
                  setFormValues(prevFormValues => ({
                   ...prevFormValues,
                   [name]: '',
                   ['fileName']: ''
                  }));
                  alert('Accepted file types:'+ types);
                }
        //  }

           }
    };

const uploadFile3 = async (filetoup,filename,dirname) => {

        let res;
        setLoadingmessage("Uploading file: "+filename+ " for test "+dirname);
        const data = new FormData();
        data.append("file", filetoup);
        data.append("filename",filename);
        data.append("dirname",dirname);

        //console.log(formValues.idfile);
        const F_URL = process.env.REACT_APP_BACKEND_URL+"/services/files";
        //console.log(F_URL);
        try{
          res = await fetch(
          F_URL,
            {
              method: 'put',
              body: data,
              // headers: {}
            })
          //console.log(res);
        } catch (err) {
            //console.log(error);
            alert("Error while uploading files. Check your connection and try again. " + err );
            return false;
          }
          return res.ok;
      }


const handleConfirm = async () => {
  if (!isSubmit) {
    setIsSubmit(true);
    setIsLoading(true);

    try {
      const formElement = document.getElementById('myForm');

      if (!formElement) {
        throw new Error('Form element not found');
      }

      await formElement.submit();

      setOpenConfirm(false); // Close the dialog after successful submission
    } catch (error) {
      alert(`An error occurred: ${error.message}`);
      setIsLoading(false);
      setIsSubmit(false);
    }
  } else {
    alert('Duplicate submission detected. Please wait for the previous submission to complete.');
  }
};

const handleSubmit = (event) => {
  event.preventDefault();
  if (!validate(formValues)) return;

  //console.log("Form is valid");
  setOpenConfirm(true);
  setShowBeforeUnloadMessage(false);
};


const validate=(values) => {
    //const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  //  const dueDate = new Date(formValues.dueDate);
  //  const middayCurrentDate = new Date(currentDate);

    // Reset time for both dates
  //  dueDate.setHours(0, 0, 0, 0);
    const dueDate = moment(formValues.dueDate).startOf('day').format('YYYY-MM-DD');


    //const phoneregex =^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$;

    const errors=[];
    //console.log(moment().diff(moment(formValues.dateofbirth), "years")>100);

    values.email=values.email.replace(/^\s+|\s+$/g, '').replace(/\s\s+/g, ' ');
    values.lname=values.lname.replace(/^\s+|\s+$/g, '').replace(/\s\s+/g, ' ');
    values.fname=values.fname.replace(/^\s+|\s+$/g, '').replace(/\s\s+/g, ' ');


      if(!values.email){
              errors.push({name:'email',value:"Email is required!"});
              //push({id: 4, name: "Mehul"})
        }
      if (!regex.test(values.email)){
              errors.push({name:'email',value: "Not a valid email format!"});
          }
  /*      if (!values.president)
            {errors.push({name:'president',value:"OMC Member Country President field is required!"});}
      if (!values.organisationName)
            {errors.push({name:"organisationName",value:"Member country Organization name is required!"});}
    if (!values.code || values.code.length>10)
        {errors.push({name:"code",value:"Country Organization Code is required!"});}*/
      if (!values.fname || values.fname.length>100)
        {errors.push({name:"fname",value:"Contact First Name is Required!"});}
      if (!values.lname || values.lname.length>100)
        {errors.push({name:"lname",value:"Contact Last Name is Required!"});}
      if (values.phone.length<8 || values.phone.length>20)
        {errors.push({name:"phone",value: "Enter a valid phone!"});}
      //Amount smaller than the invoice left.
      //console.log(formValues.discount,dueDate,currentDate);

      if( dueDate && dueDate >= currentDate ) //Get Discount
      {
      if (values.amountToPay  < formValues.totalAmount-formValues.amountPaid -formValues.discount)
          {errors.push({name:"amountToPay",value: "Enter a valid amount!"});}
      }
      else//  Discount ALSO APPLIES after date, but no message appears
      {
        if (values.amountToPay < formValues.totalAmount-formValues.amountPaid-formValues.discount)
            {errors.push({name:"amountToPay",value: "Enter a valid amount!"});}
      }
     if (formValues.pendingPayment&&values.pendFile.length===0)
        {errors.push({name:"pendFile",value: "You Must Enter the Bank Wire Transfer Receipt to Continue!"});}


      /*console.log(errors);*/
      setFormErrors(errors);

      if(errors.length===0)    return true;
      else return false;
  }

  return(
    <ThemeProvider theme={theme}>

      <div className="create">

      <h1>OMC Membership Form</h1>
        {false&&<Alert severity="warning" variant="outlined"><b>The agreed amount is valid only if there is on time payment.</b></Alert>}

        {!codeAccepted&&<Alert severity="info" variant="outlined">
        <b>Please enter organisation code and invoice number to proceed to payment.</b></Alert>}
        {<Alert severity="info" variant="outlined">

        <div style={{ textAlign: "center" }}>
    <b>OMC Membership Dues 2025. Save USD 1,000 If paid by February 15th!</b>
  </div></Alert>}



        {formErrors.length>0&&!codeAccepted&&<div className="errors">
        <Alert variant="outlined" severity="error">
        {formErrors.map((error,i)=>(<p key={i}>{error.value}</p>))}
        </Alert></div>}

    {/* <form id="myForm" action = {process.env.REACT_APP_BACKEND_URL+"/services/installment?invoiceNumber="+formValues.invoiceNumber+
        "&amount="+formValues.amountToPay+"&fname="+formValues.fname+"&lname="+formValues.lname+"&email="+formValues.email+"&phone="+formValues.phone
        +"&pendingPayment="+formValues.pendingPayment+"&comment="+formValues.comment+"&paymentType="+formValues.paymentType}
        method="POST" encType="multipart/form-data">*/}

        <form  id="myForm" action={process.env.REACT_APP_BACKEND_URL+"/services/installment"}
            method="post" encType="multipart/form-data">
    {/*       <form onSubmit={(e) => handleSubmit(e)}>*/}

        <div className="code">

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 ,border: '2px solid #ddd', padding: '10px' ,opacity: codeAccepted||isLoading ? 0.5 : 1 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' , alignItems: 'stretch' }}>

        <label htmlFor="organisationCode" style={{ display: 'block', textAlign: 'left' }}>Organisation Code</label>
        <TextField  disabled={codeAccepted||isLoading} id="organisationCode"
        type="text" required
        style ={{width: '50%' }}
        error={formErrors.some(error => error.name === "organisationCode")}
        name= "organisationCode"
        value={formValues.organisationCode}
        onChange={codeChange}
        onKeyPress={handleKeypress}
        />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'stretch'  }}>
        <label htmlFor="invoiceNumber" style={{ display: 'block', textAlign: 'left' }}>Invoice number</label>
        <TextField disabled={codeAccepted||isLoading}
        type="text" required
        style ={{width: '50%'}}
        InputLabelProps={{ style: { fontSize: '20px' } }}
        error={formErrors.some(error => error.name === "invoiceNumber")}
        name= "invoiceNumber"
        id= "invoiceNumber"
        value={formValues.invoiceNumber}
        onChange={codeChange}
        onKeyPress={handleKeypress}
        /></div>

        <Button disabled={codeAccepted||validated||isLoading} id="codebutton" color="primary" variant="contained" onClick={handleClickOpen}  autoFocus>
        Proceed to Fill Form
        </Button>
        </Box>
      </div>
{/*Confirmation Dialog*/}
  <Dialog open={openConfirm} onClose={handleCloseConfirm}>
  <DialogTitle><strong>Is the information correct?  Please review the details below before submitting: </strong></DialogTitle>
  <DialogContent>

 <Box>
 <DialogContentText>

        <ul>
          <li><b>Invoice Number: </b> {formValues.invoiceNumber}</li>
          <li><b>Organisation Name: </b> {formValues.organisationName}</li>
          <li><b>Contact Person First Name: </b> {formValues.fname}</li>
          <li><b>Contact Person Last Name: </b>{formValues.lname}</li>
          <li><b>Contact Person Email: </b>{formValues.email}</li>
          <li><b>Comments: </b>{formValues.comment}</li>

          <li><b>Amount To Pay: </b>{formValues.amountToPay}$</li>

        </ul>

        {formValues.pendingPayment && (
     <p style={{ marginTop: '10px', fontStyle: 'italic' }}>
       *Your payment will be confirmed as FULLY PAID when the OMC Bank accredits your Wire/Transfer.
     </p>
   )}
        </DialogContentText >

</Box>
  </DialogContent>
  <DialogActions>
     <Button
       disabled={isSubmit}
       onClick={handleCloseConfirm}
       style={{
         border: '2px solid #000', // Example border color
         backgroundColor: '#205396', // Light grey background
         color: '#fff',
         '&:hover': { // Styles for hover state
           /*backgroundColor: '#e0e0e0', */
           backgroundColor: '#0056b3',
         },
       }}
     >
       Cancel
     </Button>
     <Button
       disabled={isSubmit}
       onClick={handleConfirm}
       style={{
         border: '2px solid #000', // Example border color
         backgroundColor: '#205396', // Light grey background
         color: '#fff',
         '&:hover': { // Styles for hover state
           /*backgroundColor: '#e0e0e0', */
           backgroundColor: '#0056b3',
         },
       }}
     >
       Confirm
     </Button>
   </DialogActions>
</Dialog>

      <Dialog
        open={open}
        //onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
      {!codeValid&&<div>
      <DialogTitle id="alert-dialog-title">
        {codeValid?"No remaining amount":"Not a  valid combination"}
      </DialogTitle>
        {!codeValid&&<Alert severity="error">{"Please enter a valid combination!"}</Alert>}
          <DialogActions>
            <Button onClick={handleCloseNo}>Close</Button>  </DialogActions></div>
      }
      {
        codeValid&&(zeroRemaining||formValues.pendingAmount>0)&&<div>
      <DialogTitle id="alert-dialog-title">
        {codeValid&&zeroRemaining?"Nothing to pay!":"There are pending payments!"}
      </DialogTitle>
        {<Alert severity="error">
        {codeValid&&zeroRemaining?"This invoice is allready paid in full!":"Cannot proceed to other payments until OMC processes them!"}
        </Alert>}
          <DialogActions>
            <Button onClick={handleCloseNo}>Close</Button>  </DialogActions></div>
      }
        {codeValid&&!zeroRemaining&&formValues.pendingAmount<=0&&<div>
        <DialogTitle id="alert-dialog-title">
          {"Accept information below?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-title">
          {"Invoice number: " + formValues.invoiceNumber}<br />
          {"Country: "+formValues.country}<br />
          {"Organisation Name: " + formValues.organisationName}<br />
          {"President: "+formValues.president}<br />
          {"Due Date: " + moment(formValues.dueDate).format('YYYY-MM-DD') }<br />
          {"FullyPaid: " + (formValues.fullyPaid?"Yes":"No")}<br/>
          {"Payment Type: " + formValues.paymentType}<br />
          {/*"Total Invoice Amount: " + formValues.totalAmount +" $"*/}
          {"Amount Paid: " + formValues.amountPaid+" $"}<br/>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button       style={{
                 border: '2px solid #007bff', // Example border color for confirm button
                 backgroundColor: '#205396', // Blue background
                 color: '#fff', // White text color
                 '&:hover': { // Styles for hover state
                   backgroundColor: '#0056b3',
                 },
               }}onClick={handleCloseDis}>Disagree</Button>
          <Button    style={{
                 border: '2px solid #007bff', // Example border color for confirm button
                 backgroundColor: '#205396', // Blue background
                 color: '#fff', // White text color
                 '&:hover': { // Styles for hover state
                   backgroundColor: '#0056b3',
                 },
               }}
          onClick={handleCloseYes} autoFocus>
            Agree
          </Button>
        </DialogActions></div>
      }
      </Dialog>
      <Box ref={secondBoxRef} tabIndex="-1" sx={{ display: 'flex', flexDirection: 'column', gap: 2 ,border: '2px solid #ddd', padding: '10px',opacity: !codeAccepted||validated||isLoading ? 0.5 : 1 }} disabled={!codeAccepted||validated||isLoading} >

        <div className="reqfields" >
        {false&&<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'stretch'  }}>
        <label htmlFor="dueDate" style={{ display: 'block', textAlign: 'left' }}>Due Date</label>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
        minDate={new Date("2024-01-01")}
        name="dueDate"

       views={['year', 'month', 'day']}
       openTo="year"
       inputProps={{ readOnly: true }}
       style={{ flex: 1 }}
       //format="YYYY-MM-DD"
       disabled={true}
       //minDate={new Date("1920-31-12")}
       emptyLabel="Select due date"
       error={formErrors.some(error => error.name === "dueDate")}
       value={codeAccepted?formValues.dueDate:""}
       onChange={(newValue) => {
        setFormValues({ ...formValues,['dueDate']: newValue});
       }}
       renderInput={(params) => <TextField    id="dueDate"      error={formErrors.some(error => error.name === "dueDate")}
 required  style ={{width: '50%'}} readOnly  {...params} />}
/>
    </LocalizationProvider>
    </div>}
    <input
 type="hidden"
 name="invoiceNumber"
 value={formValues.invoiceNumber}
/>
<input
 type="hidden"
 name="phone"
 value={formValues.phone}
/>


          <label htmlFor="president" >OMC Member Country President</label>
          <TextField
            disabled={!codeAccepted||validated||isLoading}
            type="text"
            variant="outlined"
            required
            name="president" id="president"
            value={codeAccepted?formValues.president:""}
            onChange={handleChange}
            style ={{width: '100%'}}
            InputProps={{
               readOnly: true,
             }}
          />
          <label htmlFor="orgname">Member country organization name</label>
          <TextField
            disabled={!codeAccepted||validated||isLoading}
            type="text"
            variant="outlined"
            required
            name="orgname"
            id="orgname"
            style ={{width: '100%'}}
            InputProps={{
               readOnly: true,
             }}
            value={codeAccepted?formValues.organisationName:""}
            onChange={handleChange}
              />

            <label htmlFor="country">Country</label>
            <TextField
              disabled={!codeAccepted||validated||isLoading}
            style ={{width: '100%'}}
            InputProps={{
                   readOnly: true,
                 }}
            name="country"
              id="country"
            value={codeAccepted?formValues.country:""}
            //onChange={handleChange}
            >

            </TextField>

            <label htmlFor="fname">Contact Person First Name</label>
            <TextField type="text" required
            error={formErrors.some(error => error.name === "fname")}
            disabled={!codeAccepted||validated||isLoading}
            style ={{width: '100%'}}
            name = "fname" value={formValues.fname}
            id = "fname"
            onChange={handleChange}/>

            <label htmlFor="lname">Contact Person Last Name</label>
            <TextField type="text" required
              error={formErrors.some(error => error.name === "lname")}
            disabled={!codeAccepted||validated||isLoading}
            style ={{width: '100%'}}
            name = "lname" value={formValues.lname}
            id = "lname"
            onChange={handleChange}
            />
            <label htmlFor="email">Contact Email</label>
            <TextField type="email" required
            error={formErrors.some(error => error.name === "email")}
            disabled={!codeAccepted||validated||isLoading}
            style ={{width: '100%'}}
            name= "email" id= "email"  value={formValues.email}
            onChange={handleChange}
            autoComplete="email"
            />

            <label id="contactPhoneLabel">Contact Phone</label>
            <PhoneInput required key="phone"   countryCodeEditable
            aria-label="Contact Phone"
            inputStyle={inputStyle}
            disabled={!codeAccepted||validated||isLoading}
            value={formValues.phone}
            onChange={(newValue) => {
             setFormValues({ ...formValues,['phone']: newValue});
            }}
            />
            <input type="hidden" name="phone" id="contactPhone" value={formValues.phone}/>
            </div>


          {/*  <Stack direction="column" className="agree">
            <label>
            <Checkbox color="default"
                      id="agree"
                      checked={isChecked}
                      onChange={checkHandler}/>
            I, the undesigned, have read and approve this discharge and release </label>
            <a href="https://omchairworld.com/terms-and-conditions/" target="_blank" rel="noopener noreferrer">Terms&Conditions</a>
            </Stack>*/}

</Box>
          {/*<Alert severity="warning" variant="outlined"><b>Do not enter organisation code here. Ask your president for pending payment code which allows you to pay later.</b></Alert>*/}

          <label htmlFor="comments">Comments</label>
          <TextField inputProps={{ maxLength: 200 }}
          id="comments"
          disabled={!codeAccepted||validated||isLoading}
          style ={{width: '100%'}}
          name= "comment" value={formValues.comment}
          multiline
          rows={3}
          variant="outlined"
          placeholder="Your comment..."
          onChange={handleChange}/>

        <Stack justifyContent="flex-start" sx={{ pl: 2, pt: 2, pb:1 }}  direction="row" spacing={2}>
         <RadioGroup
          aria-label="pendingPayment"
          name="pendingPayment"
          value={formValues.pendingPayment}
          onChange={pendingPaymentChange}
         >
          <FormControlLabel   disabled={!codeAccepted||validated||isLoading} value="true" control={<Radio />} label="Wire/Bank transfer" />
          <Stack spacing={2} direction="column">
        {<div><input style={{ display: 'none' }}  key="pendFile" type="file" onChange={handleChange} id="pendFile"  name = "pendFile" accept={types}
          readOnly={!codeAccepted || validated || isLoading||!formValues.pendingPayment} />
          {<label htmlFor="pendFile">Upload Wire/Bank Transfer Receipt<span className="reqfiles">*</span><br/>
             <Button variant="contained" color="primary" size="small"
             disabled={!codeAccepted||validated||isLoading||!formValues.pendingPayment}

    sx={ buttonStyle }

              component="span">
               {formValues.pendFile?"Change Selection":"Upload file"}
             </Button>

             {formValues.pendFile&&<p style={{ fontWeight: 'bold', color: '#2196f3' , fontSize: '15px' }}>File selected: {formValues.pendFile.name}</p>}
            </label>}</div>}
            {formValues.pendFile&&<Button variant="contained" color="primary" size="small"
            disabled={!codeAccepted||validated||isLoading}
            sx={ buttonStyle }


   onClick={() => setViewPendingFile(true)}
             component="span">
              View File
            </Button>}</Stack>
          <FormControlLabel   disabled={!codeAccepted||validated||isLoading} value="false" control={<Radio />} label="Card Payment" />
         </RadioGroup>
       <input type="hidden" id="fileName" name="fileName" value={formValues.fileName.toLowerCase()} />
        </Stack>

        {formValues.pendFile && (
        <Dialog open={viewPendingFile} onClose={() => {
                      setViewPendingFile(false);
                        setPageNumber(1);
                        }}
 PaperProps={{ style: { maxHeight: '90vh', width: '90vw' } }}>
        <DialogTitle>
        <Typography variant="h7" component="div" sx={{ flexGrow: 1 }}>
        File to Upload
        </Typography>
        <Tooltip title="Close">
        <IconButton edge="end" color="inherit" onClick={() => {
                      setViewPendingFile(false);
                        setPageNumber(1);
                        }}aria-label="close">
        <CloseIcon />
        </IconButton>
        </Tooltip>
        <Tooltip title="Go to Previous Page" placement="top">
         <IconButton onClick={() => setPageNumber(prev => Math.max(1, prev - 1))}>
           <ArrowBackIcon />
         </IconButton>
       </Tooltip>
       <Tooltip title="Go to Next Page" placement="top">
         <IconButton onClick={() => setPageNumber(prev => Math.min(numPages, prev + 1))}>
           <ArrowForwardIcon />
         </IconButton>
       </Tooltip>

        </DialogTitle>
        <DialogContent>
        {formValues.pendFile.type.startsWith('image/')? (
        <img
        key={'p'}
        id="photo"
        src={URL.createObjectURL(formValues.pendFile)}
        alt="Selected File"
        style={{maxWidth: '100%', height: 'auto'}}
        />
      ) : formValues.pendFile.type === 'application/pdf' ? (
        <Box>
        <Document
      file={URL.createObjectURL(formValues.pendFile)}
      onLoadSuccess={({ numPages }) => setNumPages(numPages)}
    >
      <Page pageNumber={pageNumber} />
    </Document>

    </Box>
        ) : (
        <iframe
        src={URL.createObjectURL(formValues.pendFile)}
        style={{width: '100%', height: '100%'}}
        title="PDF Viewer"
        allowFullScreen
        />
        )}

        </DialogContent>
        </Dialog>
        )}

      {false&&  <FormControl sx={{ flex: 1 }}>
        <label style={{ fontWeight: "bold" }} >Remaining Invoice Amount</label>
        <TextField
        readOnly
        variant="outlined"
        disabled = {true}
        name= "totalAmount"
        value={formValues.totalAmount?formValues.totalAmount-formValues.amountPaid:0}
        InputProps={{
 startAdornment: <InputAdornment position="start">$</InputAdornment>,
}}
        />
          </FormControl>}

        <Stack direction="row" justifyContent="flex-end"  alignItems="center" sx={{ pb: 2 }}>
        <FormControl>
        <label htmlFor="amountToPay" style={{ fontWeight: "bold" }} >Payment Amount</label>
        <TextField
         disabled={!codeAccepted||validated||isLoading}
        variant="outlined"
        error={formErrors.some(error => error.name === "amountToPay")}
        onChange={handleChange}
        name= "amountToPay"
        id= "amountToPay"
        value={formValues.amountToPay}
        style={{ width: '100%'}}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          /*readOnly: true*/
        }}
        />
        </FormControl>
        </Stack>

        <div className="discount">
        {( formValues.dueDate>=currentDate &&
          formValues.dueDate !== '' &&
          formValues.amountToPay >= formValues.totalAmount -formValues.amountPaid- formValues.discount) && (
          <Alert variant="outlined" severity="success">
            <strong>{`Congratulations! You are doing the payment before the deadline, so discount will be applied and you will save ${formValues.discount}$`}</strong>

          </Alert>
        )}
      </div>

          {formErrors.length>0&&<div className="errors">
          <Alert variant="outlined" severity="error">
          {formErrors.map((error,i)=>(<p key={i}>{error.value}</p>))}
          </Alert></div>}

          {<div>
            <Button endIcon={validated && <CreditCardIcon/>}
            disabled={isLoading  || !codeAccepted} color="primary"
            onClick={handleSubmit}
            type="submit" variant="contained" autoFocus>
              "Pay now" {formValues.amountToPay}$
            </Button>
            </div>}

          {isLoading &&
          <div className="circular" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                        <CircularProgress thickness={1} size={150}/>
          <span style={{ justifyContent: "center", position: "fixed", top: "65%" }}>{loadingmessage}</span>
          </div>
          }
        {isLoading &&  <div id="loadingSpinner" style={{display: 'none'}}>Uploading...</div>}

        </form>
      </div>
      </ThemeProvider>
  );
}

export default CreateRegistration;
