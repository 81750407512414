import React from "react";


const Navbar = () => {

return (
    <nav className="navbar" >
      <h1>  </h1>
         <a  href="https://omchairworld.com/">

              <img src={process.env.PUBLIC_URL + "/Form Banner3.jpg"}  alt="logo" width= "100%" height="120" />

          </a>

      </nav>
);

}


export default Navbar;
