//import Navbar from './Navbar';
//import { useState,useEffect } from 'react';
import React from "react";
//import { useParams } from "react-router-dom";
//import CancelIcon from '@mui/icons-material/Cancel';
import Alert from '@mui/material/Alert';


function CancelPage() {
  //const {id} = useParams();
  //console.log(id);


  return (
    <div className="SuccessPage">
        <div  style={{ width: "100%"}}>
{/*        <CancelIcon color="secondary"

{false&&<form action = {process.env.REACT_APP_BACKEND_URL+"/services/installment?invoiceNumber="+formValues.invoiceNumber+
"&amount="+formValues.amountToPay+"&fname="+formValues.fname+"&lname="+formValues.lname+"&email="+formValues.email+"&phone="+formValues.phone
+"&pendingPayment="+formValues.payLater+"&comment="+formValues.comment+"&paymentType="+formValues.paymentType}
method="POST">}

    sx={{display: "flex", justifyContent: "flex-end",color:"red", fontSize:"40px" }}/>*/}
          <Alert severity="error" variant="outlined"><b>Unsuccessfull Payment. Please start over!</b></Alert>

        </div>
    </div>
  );
}

export default CancelPage;
